'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

# スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,{
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
  }
)

$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  # フッター高さ取得
  footerHeight =
    ()->
      add = 0
      height = $('.l-footer').height()
      outerHeight = $('.l-footer').outerHeight(true)
      diff = height - outerHeight
      if diff > 0
        $('.l-content').css('padding-bottom',height+add)
        $('.l-footer').css('height',height)
      else
        $('.l-content').css('padding-bottom',outerHeight+add)
        $('.l-footer').css('height',height)
      return

  footerHeight()

  $(window).on('load resize',
    ->
      footerHeight()
  )

  $('a[href^="#"]'+'a[href!="#"]').on('click.smoothScroll'
    (e)->
      e.preventDefault()

      $('body').animate({
        scrollTop:$($(this).attr('href')).offset().top - 40
      },{
        easing:'easeInOutCirc',
        duration:1000
      })
  )

  $('.h-mainvisual-pic').slick({
    autoplay: true
    fade: true
    draggable: false
    swipe: false
    arrows: false
    autoplaySpeed: 7000
    speed: 3000
  })
  
  $('.h-mainvisual-text').slick({
    autoplay: true
    fade: true
    draggable: false
    swipe: false
    arrows: false
    autoplaySpeed: 7000
    speed: 3000
  })
  
  $('.h-mainvisual-text').on('setPosition',
    (s, c, n)->
      $(c.$slides[c.currentSlide]).addClass('is-animate')
  )
  
  $('.h-mainvisual-text').on('beforeChange',
    (s, c, n)->
      if $(c.$slides[n + 1]).length isnt 0
        $(c.$slides[n + 1]).addClass('is-animate')
      else
        $(c.$slides[0]).addClass('is-animate')
  )
  
  $('.h-mainvisual-text').on('afterChange',
    (s, c, n)->
      if $(c.$slides[n - 1]).length isnt 0
        $(c.$slides[n - 1]).removeClass('is-animate')
      else
        $(c.$slides[c.slideCount - 1]).removeClass('is-animate')
  )
  
  $('.m-case-pic').slick({
    asNavFor: '.m-case-thumb'
    autoplay: true
  })
  
  $('.m-case-thumb').slick({
    asNavFor: '.m-case-pic'
    fade: true
    slideToShow: 4
    slidesToScroll: 4
    variableWidth: true
    draggable: false
    arrows: false
    swipe: false
    # centerMode: true
    focusOnSelect: true
  })
  
  $('.m-about-slide, .m-clinic-slide').slick({
    autoplay: true
    fade: true
    draggable: false
    swipe: false
    arrows: false
    autoplaySpeed: 7000
    speed: 3000
  })
  
  $('.m-clinic-item-title').on('click',
    ()->
      $(this).parent('.m-clinic-item').toggleClass('is-open')
  )
  
  contentsPos = {}
  
  $('.m-contents').each(
    ()->
      contentsPos[$(this).attr('id')] = $(this).offset().top
  )
  
  $('.h-navi-item').find('a').on('click',
    (e)->
      e.stopPropagation()
  )
  
  $(window).scroll(
    ()->
      $window = $(this)
      $pagetop =$('.l-pagetop')
      $gnavi =$('.gnavi')
  
      if $(this).scrollTop() > $(this).height()
        if not $pagetop.hasClass('is-active')
          $pagetop.addClass('is-active')
      else
        if $pagetop.hasClass('is-active') and $pagetop.hasClass('is-animated')
          $pagetop.removeClass('is-animated')
  
      $pagetop.on('oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
        ()->
          if $window.scrollTop() > $window.height()
            $pagetop.addClass('is-animated')
          else
            $pagetop.removeClass('is-active')
      )
  
      if $window.scrollTop() > $('.l-header').innerHeight()
        $gnavi.addClass('is-active')
      else
        $gnavi.removeClass('is-active')
  
      $.each(contentsPos,
        (key, val)->
          if $window.scrollTop() >= ($('#'+key).position().top - 41) and
          $window.scrollTop() < $('#'+key).position().top + $('#'+key).height()
            $gnavi.find('.'+key).addClass('is-active')
            $('.h-navi-item').not('.'+key).removeClass('is-active')
  
          if $window.scrollTop() < $('.l-header').innerHeight()
            $('.h-navi-item').removeClass('is-active')
      )
  )
  
)
